import { Component, Input } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';
	numberToCurrency = numberToCurrency;
	getBasePrice = getBasePrice;

	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(this.article, [], this.article.defaults, OrderType.PREORDER, this.preorderType);
			articleGroup.quantity = 1;
			const groups = this.test(articleGroup);
			if (
				groups.length > 0 &&
				this.article?.tags?.length > 0 &&
				this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0
			) {
				const DIMA = groups
					.filter(it => it.requiredAmount > 0)
					.map(it => {
						return Math.min(...it.articles.map(article => numberD(article.price) * it.requiredAmount));
					});
				this.price = DIMA.reduce((a, b) => a + b, 0) + this.price;
			} else {
				this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
			}
		} else {
			this.price = 0;
		}
		this.priceText = numberToCurrency(this.price, this.currency);
	}

	checkVeganFeature(article: Article) {
		return Object.entries(article.compound?.features)
			.filter(it => it[0] == 'vegan' || it[0] == 'vegetarian')
			.filter(it => !!it[1])
			.map(it => it[0])[0];
	}
	findFeature(article: Article) {
		if (article?.compound?.features) {
			delete article.compound?.features?._id;
			delete article.compound?.features?.updatedAt;
			delete article.compound?.features?.createdAt;
			delete article.compound?.features?.createdAt;
			return Object.entries(article.compound?.features)
				.filter(it => it[0] == 'new' || it[0] == 'special' || it[0] == 'topSelling')
				.filter(it => !!it[1])
				.map(it => it[0])[0];
		}
		return '';
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		const indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (page.length > 0 && articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	checkFromPriceTag(article: Article) {
		return this.article?.tags?.length > 0 && this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0;
	}
}

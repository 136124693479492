import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

export enum PageEnum {
	privacy = 'privacy',
	agb = 'agb',
	imprint = 'imprint',
}

@Component({
	selector: 'app-html-visualizer-modal',
	templateUrl: './html-visualizer-modal.component.html',
	styleUrls: ['./html-visualizer-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlVisualizerModalComponent implements OnInit {
	public venue;
	public page: PageEnum;
	public pageEnum = PageEnum;
	constructor(
		public modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	ngOnInit(): void {}

	static async show(modalCtrl: ModalController, venue: Venue, page: PageEnum) {
		const modal = await modalCtrl.create({
			component: HtmlVisualizerModalComponent,
			cssClass: 'html-modal',
			componentProps: {
				venue,
				page,
			},
			backdropDismiss: true,
			showBackdrop: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	getHtml() {
		if (!!this.venue) {
			if (this.page === this.pageEnum.privacy) {
				return this.venue.legal.privacy;
			}
			if (this.page === this.pageEnum.agb) {
				return this.venue.legal.terms;
			}
			if (this.page === this.pageEnum.imprint) {
				return this.venue.legal.imprint;
			}
		} else {
			if (this.page === this.pageEnum.privacy) {
				return this.translate.instant('privacy_page').data;
			}
			if (this.page === this.pageEnum.agb) {
				return this.translate.instant('tos_page').data;
			}
			if (this.page === this.pageEnum.imprint) {
				return this.translate.instant('impressum_page').data;
			}
		}
	}
}

import { Component } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { SignInPage } from '../../pages/sign-in/sign-in.page';
import { Router } from '@angular/router';
import { SignUpPage } from '../../pages/sign-up/sign-up.page';
import { PopoverController, ModalController } from '@ionic/angular';
import { HomePage } from '../../pages/home/home.page';
import { MyOrdersPage } from '../../pages/my-orders/my-orders.page';
import { AccountPage } from '../../pages/account/account.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import { TosPage } from '../../pages/tos/tos.page';
import { ImpressumPage } from '../../pages/impressum/impressum.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';
import { WorkingHoursModalComponent } from '../working-hours-modal/working-hours-modal.component';
import Article from 'src/smoothr-web-app-core/models/Article';
declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-menu-modal',
	templateUrl: './menu-modal.component.html',
	styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent extends RepositoryDirective {
	environment = environment;
	languageEnum = Language;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private modalController: ModalController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService,
		private navigationService: NavigationService
	) {
		super(repository);
	}

	async signIn() {
		await this.modalController.dismiss();
		await this.navigationService.signIn();
	}

	async signUp() {
		await this.modalController.dismiss();
		await this.navigationService.signUp();
	}

	async mySpace() {
		await this.modalController.dismiss();
		await this.navigationService.account();
	}

	async myOrders() {
		await this.modalController.dismiss();
		await this.navigationService.myOrders();
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_success'), null, {
				duration: 2000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('menu_popover.logout_error'), null, {
				duration: 2000,
			});
		}
		await this.modalController.dismiss();
	}

	async home() {
		await this.modalController.dismiss();
		await this.navigationService.home();
	}

	async privacy() {
		await this.modalController.dismiss();
		await this.navigationService.privacy();
	}

	async tos() {
		await this.modalController.dismiss();
		await this.navigationService.tos();
	}

	async impressum() {
		await this.modalController.dismiss();
		await this.navigationService.imprint();
	}
	async allergens() {
		await this.modalController.dismiss();
		// TODO open PDF
	}

	dismiss() {
		this.modalController.dismiss();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
		let b = (ad: any) => {};
	}
	async storeInfo() {
		WorkingHoursModalComponent.show(this.modalController, this.venue, this.order?.preorder?.type);
		await this.modalController.dismiss();
	}
}

import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const tabiloCustomerGroup = ['tabilo_sim', 'tabilo'];
export const customerGroup = tabiloCustomerGroup;

const supportEmail = {
	tabilo: 'tabilo@smoothr.de',
};
const firebaseConfig = {
	tabilo: {
		apiKey: 'AIzaSyCAxZEOD_R7Y6oEPOXiGux86jIWL75rN-k',
		authDomain: 'gastronomie-online-order-6e7e4.firebaseapp.com',
		projectId: 'gastronomie-online-order-6e7e4',
		storageBucket: 'gastronomie-online-order-6e7e4.appspot.com',
		messagingSenderId: '496947315731',
		appId: '1:496947315731:web:d2e9dc82b023b9cfd0ebe6',
		measurementId: 'G-HMGTXRKWDL',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCHgpycB0gFwahyYXgLqA3fHreIeinNcPA',
	SUPPORT_EMAIL: supportEmail[customerGroup[1]],
	firebaseConfig: firebaseConfig[customerGroup[1]],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
	tableNumberSimphony: '110',
	tableIDSimohony: '64b69be624a61a9885e0a753',
};

import 'zone.js/dist/zone-error';

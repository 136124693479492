import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, ViewDidEnter } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuPage } from '../menu/menu.page';
import { AppComponent } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { InformationModalComponent } from 'src/app/components/information-modal/information-modal.component';
import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss'],
})
export class OrderPage extends RepositoryDirective implements OnInit, ViewDidEnter {
	static url = 'order';
	static failUrlWithPaymentParamCVV = 'payment/failed/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static paymentFailUrlCVV = 'payment/failed';
	static paymentFailUrl = 'payment/fail';

	static paymentCancelUrl = 'payment/cancel';
	loading = false;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private snackbarCtrl: MatSnackBar,
		private navigationService: NavigationService,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get title(): string {
		return this.translate.instant('menu_page.basket_title');
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log('ngOnInit');

		if (window.location.pathname.includes(OrderPage.paymentFailUrl) || window.location.pathname.includes(OrderPage.paymentFailUrlCVV)) {
			InfoModalComponent.show(this.modalCtrl, {
				info: this.translate.instant('order.payment_fail'),
				closeButton: this.translate.instant('short_info.close'),
			});
		}
		if (window.location.pathname.includes(OrderPage.paymentCancelUrl)) {
			InfoModalComponent.show(this.modalCtrl, {
				info: this.translate.instant('order.payment_cancel'),
				closeButton: this.translate.instant('short_info.close'),
			});
		}
		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
		}
	}
	ionViewDidEnter(): void {
		console.log('DID ENTER ORDER PAGE');
	}

	async backClick() {
		await this.navigationService.menu();
	}
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import { CookieService } from 'ngx-cookie-service';
declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss'],
})
export class PrivacyPage implements OnInit, OnDestroy {
	static url = 'privacy';
	checkIfVenueExistPrivacy = '';
	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private cookieService: CookieService
	) {}
	ngOnInit(): void {}
	ngOnDestroy(): void {}
	ionViewDidEnter() {
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.privacy) {
				this.checkIfVenueExistPrivacy = this.repository._venue?.legal?.privacy;
			} else {
				this.checkIfVenueExistPrivacy = '';
			}
		} else {
			this.checkIfVenueExistPrivacy = '';
		}
	}
	async goBack() {
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}

	async editCookies() {
		try {
			await this.analytics.setAnalyticsCollectionEnabled(false);
		} catch (e) {
			console.log(e);
		}
		console.log('Test');

		this.cookieService.deleteAll();
		// document.cookie.split(';').forEach(function (c) {
		// 	document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
		// 	console.log(document.cookie)
		// });
		await this.analytics.setAnalyticsCollectionEnabled(false);

		console.log(navigator.cookieEnabled);

		const findDialogWithCookie = document.getElementsByClassName('cc_css_reboot');

		await sleep(500);
		await this.initCookieConsent();

		// window.location.reload();
	}
	private async initCookieConsent() {
		console.log(cookieConsent);
		this.cdr.detectChanges();
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup[1]),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup[1]),
			});
			console.log(cookieConsentData);
		};
		const a = await onContentLoaded();
		console.log(a);
		cookieConsentCallback = async () => {
			await this.handleCookieConsent();
		};
		// await this.handleCookieConsent();
	}

	async handleCookieConsent() {
		console.log('handleCookieConsent()', cookieConsentData);
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}
}

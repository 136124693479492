import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss'],
})
export class MapPage {
	constructor(private navService: NavigationService) {}
	static url = 'map';

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}
	goHome() {
		this.navService.home();
	}
}

import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { ScanQrModal } from 'src/app/components/scan-qr/scan-qr.component';
import { ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectTableModalComponent } from 'src/app/components/select-table-modal/select-table-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { BehaviorSubject } from 'rxjs';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';
import moment from 'moment';
import { AppComponent } from 'src/app/app.component';
import { MapsUtils } from 'src/maps/utils/maps';
import { InformationModalComponent } from 'src/app/components/information-modal/information-modal.component';
import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.tabilo.html',
	styleUrls: ['./home.page.tabilo.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanQr = 'scan-qr/:venueId/:tableId';
	static failedUrl = 'payment/failed/:orderId';
	environment = environment;
	languageEnum = Language;

	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	loading$ = new BehaviorSubject(false);
	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute
	) {
		super(repository);
	}

	ngOnInit() {
		// super.ngOnInit();
		// this.route.paramMap.subscribe(params => {
		// 	if (params && params.get('venueId') && params.get('tableId')) {
		// 		this.loadVenueByQrCode(params.get('venueId'), params.get('tableId'));
		// 	} else {
		// 		// this.loadVenues();
		// 	}
		// });
		super.ngOnInit();
		this.route.paramMap.subscribe(params => {
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('tableNumber'), params.get('venueId'));
			}
		});
		if (window.location.pathname.includes(NAVIGATIONURLS.paymentFail())) {
			this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
				duration: 6000,
			});
		}
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.onOrderChange(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
			repository.onOrderChange(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ionViewDidEnter() {
		this.repository.onOrderChange(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.emit(null);
		this.repository.payment.emit(null);
		this.repository.venue.emit(null);
		this.repository.onOrderChange(null);
		this.repository.resetVenue();
	}
	async locateAndShowStores() {
		const allCookie = document.cookie.split(';').find(it => !!it);
		if (!allCookie) {
			await InfoModalComponent.show(this.modalCtrl, {
				info: this.translate.instant('not_accept_cookie'),

				closeButton: this.translate.instant('short_info.close'),
			});
			return;
		}
		this.loading$.next(true);

		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
			this.loading$.next(false);
		}
		this.repository.address.emit(null);
		await this.navigationService.map();
		this.loading$.next(false);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading$.next(true);
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			if (venueData && table) {
				this.repository.venue.emit(venueData);
				this.repository.createOrder(this.venue, null, OrderType.PREORDER, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.onOrderChange(this.order);
				sleep(200);
				await this.navigationService.menu();
				await SelectTableModalComponent.show(this.modalCtrl, this.order);
			}
			this.loading$.next(false);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000,
			});
			this.loading$.next(false);
		}
	}
	openPrivacy() {
		this.navigationService.privacy();
	}
	openImpressum() {
		this.navigationService.imprint();
	}
	openAgb() {
		this.navigationService.tos();
	}
}

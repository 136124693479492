import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app.component';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { CustomAddressModalComponent } from '../custom-address-modal/custom-address-modal.component';
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

@Component({
	selector: 'app-check-delivery-radius-modal',
	templateUrl: './check-delivery-radius-modal.component.html',
	styleUrls: ['check-delivery-radius-modal.component.scss'],
})
export class CheckDeliveryRadiusModalComponent extends RepositoryDirective implements OnInit {
	@ViewChild(IonInput, { static: true }) inputField: IonInput;
	@Input() selectedVenue: Venue;
	loading = true;
	venuesLoaded = false;
	searchTerm: string;
	address: Address;
	showEmpty = false;
	suggestedPlace: Address;
	deliveryVenueAvailable = false;
	predictions: AutocompletePrediction[] = [];
	addressToString = MapsUtils.addressToString;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController,
		public platform: Platform
	) {
		super(repository);
	}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<{ venue: Venue; address: Address }> {
		console.log(venue);
		const modal = await modalCtrl.create({
			component: CheckDeliveryRadiusModalComponent,
			cssClass: 'delivery-modal',
			componentProps: {
				selectedVenue: venue,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	async ngOnInit() {
		MapsUtils.getUserGeocode().then(result => {
			this.suggestedPlace = result;
		});

		await this.initAutocomplete();
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async loadPlace(pred: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			await this.fillInPlace(result);
			this.predictions = [];
		} catch (e) {
			await this.fillInPlace(pred);
		}

		this.loading = false;
		this.cdr.detectChanges();
	}

	async resetSearch() {
		(await this.inputField.getInputElement()).value = '';
		this.searchTerm = '';
		this.venuesLoaded = false;
		this.deliveryVenueAvailable = false;
		this.predictions = [];
	}

	async loadVenues(address: Address) {
		console.log('selectedVenue', this.selectedVenue);
		this.deliveryVenueAvailable = false;
		this.venuesLoaded = false;
		if (address.street === null || address.street === undefined) {
			this.address = null;
			this.loading = false;
			this.snackBar.open(this.translate.instant('home_page.street_error'), null, {
				duration: 2000,
			});
			this.cdr.detectChanges();
			return;
		}
		try {
			// const foundVenue = (await this.repository.getVenuesByAddress(address)).find(
			// 	ven => ven._id === this.selectedVenue._id && venueAcceptsOrders(ven, PreorderType.DELIVERY)
			// );
			// this.deliveryVenueAvailable = foundVenue !== undefined;
			this.venuesLoaded = true;
		} catch (e) {
			console.error(e);
			this.venuesLoaded = false;
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(address: Address) {
		this.loading = true;
		try {
			await MapsUtils.fillInPlace(this.inputField, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				await this.executeSearch();
				return;
			}
			this.address = address;
			await this.loadVenues(address);
			this.loading = true;
		} catch (e) {
			this.snackBar.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async dismiss(address: Address) {
		this.modalCtrl.dismiss({
			selectedVenue: this.selectedVenue,
			address,
		});
	}

	async executeSearch() {
		console.log('here');
		this.predictions = [];
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.inputField);
			console.log('result', result);

			await this.fillInPlace(result);
		} catch (e) {}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async initAutocomplete() {
		this.showEmpty = false;
		if (this.inputField) {
			await MapsUtils.initAutocomplete(this.inputField, predictions => {
				console.log(predictions);
				this.predictions = predictions;
				this.showEmpty = predictions.length === 0;
				this.cdr.detectChanges();
			});
		}

		this.loading = false;
	}

	async delivery() {
		await this.modalCtrl.dismiss({
			venue: this.selectedVenue,
			address: this.address,
		});
	}

	async showAddressModal() {
		// const result: {address: Address | null} =
		// 	await CustomAddressModalComponent.show(this.modalCtrl);
		// if (result.address) {
		// 	this.fillInPlace(result.address as Address);
		// }
	}
	async close() {
		await this.modalCtrl.dismiss();
	}
}

import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { defaultsToArticleOption, getPrice, getSlotsByOrder, isDepositArticle, numberD } from '../../../smoothr-web-app-core/utils/utils';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController, PickerController } from '@ionic/angular';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { Router } from '@angular/router';
import { sleep, isVenueOpen, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { environment } from 'src/environments/environment';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HtmlVisualizerModalComponent, PageEnum } from '../html-visualizer-modal/html-visualizer-modal.component';

export class PhoneCountry {
	code: string;
	tel: string;
	icon: string;
}
@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	isVenueOpen = false;
	tip: number;
	openedSalesForceBlock = false;
	preferredCountries = ['de'];
	defaultCountry = 'de';
	phoneCountries: PhoneCountry[] = [
		{
			code: 'de',
			tel: '+49',
			icon: '../../../assets/tabilo/de_flag.svg',
		},
		{
			code: 'au',
			tel: '+43',
			icon: '../../../assets/tabilo/au_flag.svg',
		},
	];

	pageEnum = PageEnum;

	orderForm: FormGroup;
	name = 'name';
	surname = 'surname';
	email = 'email';

	phone = 'phone';
	note = 'note';
	orderAt = 'orderAt';
	privacy = 'privacy';
	salesforce = 'salesforce';

	numberToCurrency = numberToCurrency;
	slots: Moment[] = [];
	environment = environment;

	@ViewChild(IonContent, { static: false }) content;
	@ViewChild(IonSlides, { static: false }) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	isOverviewPage = false;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}

	static async show(modalCtrl: ModalController, analytics: AnalyticsService, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip,
				order,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		analytics.beginCheckout(order);
		return await modal.onDidDismiss();
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.createOrderForm();
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		this.cdr.detectChanges();
	}

	checkPrevOrder() {
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				this.orderForm.patchValue({
					name: this.repository._previousPreorder?.name ? this.repository._previousPreorder?.name.split(' ')[0] : '',
					surname: this.repository._previousPreorder?.name ? this.repository._previousPreorder?.name.split(' ')[1] : '',
					email: this.repository._previousPreorder?.email ?? '',
					phone: {
						internationalNumber: this.repository._previousPreorder?.phone ?? '',
						dialoCode: '+49',
						isoCode: 'de',
						nationalNumber: this.repository._previousPreorder?.phone.trim().replace('+49', ''),
					},
					phoneCode: this.repository._previousPreorder?.phone ? this.repository._previousPreorder?.phone.slice(0, 3) : '+49',
				});
			}
		}
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode = this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			// if (!this.order.preorder.floor) {
			// 	this.order.preorder.floor = this.customer.floor;
			// }
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			// if (this.order.preorder.type === PreorderType.DELIVERY) {
			// 	if (!this.order.preorder.street) {
			// 		this.order.preorder.street = this.customer.street;
			// 	}
			// 	if (!this.order.preorder.postalCode) {
			// 		this.order.preorder.postalCode = this.customer.postalCode;
			// 	}
			// 	if (!this.order.preorder.city) {
			// 		this.order.preorder.city = this.customer.city;
			// 	}
			// 	if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
			// 		this.order.preorder.number = this.customer.number;
			// 	}
			// }
		}
	}

	async close() {
		await this.updateForm();

		const data = {
			preorder: this.order.preorder,
			orderAt: this.getFormControlValue('orderAt'),
			asap: this.order.asap,
			sendToSalesforce: this.order.sendToSalesforce,
		};
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async updateForm() {
		this.order.preorder.name = this.getFormControlValue('name') + ' ' + this.getFormControlValue('surname');
		this.order.preorder.phone = this.getFormControlValue('phone')?.internationalNumber;
		this.order.preorder.email = this.getFormControlValue('email');
		this.order.preorder.note = this.getFormControlValue('note');
		this.order.orderAt = this.getFormControlValue('orderAt');
		this.order.sendToSalesforce = this.getFormControlValue(this.salesforce) ?? false;
		if (!this.order.orderAt) {
			this.order.asap = true;
		}
		// if (this.order.preorder.type === PreorderType.DELIVERY) {
		// 	this.order.preorder.city = this.getFormControlValue('city');
		// 	this.order.preorder.postalCode = this.getFormControlValue('postalCode');
		// 	this.order.preorder.number = this.getFormControlValue('number');
		// 	this.order.preorder.street = this.getFormControlValue('street');
		// }
	}
	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (this.order && this.order.preorder && this.address && this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		const asapPossible = (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) && this.isVenueOpen;
		if (!slot) {
			return this.translate.instant(asapPossible ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot');
		}
		const slotMoment = moment(slot);
		const sameDay = TimeUtils.isSameDate(slotMoment, moment());
		if (sameDay) {
			return slotMoment.format('HH:mm');
		} else {
			return slotMoment.format('DD.MM. HH:mm');
		}
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	async onButtonClick() {
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}

	async toOverview() {
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}
	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}
	slotSelected($event) {
		this.order.orderAt = $event;
		if (!$event) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
	}
	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}
	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot => moment(slot.time));
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.loading = false;
	}
	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		return price;
	}
	createOrderForm() {
		const formControlNames: any = {};

		formControlNames[this.name] = ['', Validators.required];
		formControlNames[this.surname] = [''];

		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				),
			],
		];
		// formControlNames[this.phoneCode] = ['+49'];
		formControlNames[this.phone] = ['', [this._customPhoneValidatorForLibrary]];

		formControlNames[this.note] = ['', []];
		formControlNames[this.orderAt] = [''];
		if (!this.customer.isAnonymous) {
			formControlNames[this.salesforce] = [this.customer?.salesforceEnabled ?? false];
			formControlNames[this.privacy] = [true];
		} else {
			formControlNames[this.privacy] = [false, [Validators.required, Validators.requiredTrue]];
		}

		this.orderForm = this.fb.group(formControlNames);
		// this.checkPrevOrder();

		this.checkCustomer();
	}
	private _customPhoneValidatorForLibrary(control: AbstractControl) {
		if (!control.value) {
			return null;
		}
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value?.internationalNumber);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}
	checkCustomer() {
		console.log(this.customer);
		if (this.customer) {
			if (this.customer?.name) {
				console.log('HERE', this.customer.name.split(' '));
				this.orderForm.patchValue({
					name: this.customer.name.split(' ')[0],
					surname: this.customer?.name.replace(this.customer.name.split(' ')[0], ''),
				});
			}
			if (this.customer?.email) {
				this.orderForm.patchValue({
					email: this.customer?.email,
				});
			}
			if (this.customer?.phone) {
				this.orderForm.patchValue({
					phone: {
						internationalNumber: this.customer?.phone ?? '',
						dialoCode: '+49',
						isoCode: 'de',
						nationalNumber: this.customer?.phone.trim().replace('+49', ''),
					},
				});
			}
		}
	}
	getFormControlValue(controlName?: string) {
		if (controlName) {
			return this.orderForm.get(controlName)?.value;
		}
		return '';
	}
	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.hasError('required') && this.orderForm.get(controlName)?.touched;
		}
		return this.orderForm?.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.errors && !this.orderForm.get(controlName)?.hasError('required');
		}
		return this.orderForm.invalid;
	}

	getErrorsForm(controlName) {
		if (controlName) {
			return this.orderForm.get(controlName).errors;
		}

		return null;
	}

	private _customPhoneValidator(control: AbstractControl) {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}
	addOptional(): string {
		return this.translate.instant('checkout_modal.optional');
	}
	checkArticleTagEmpty(event: ArticleOption) {
		if (event.article.tags?.length > 0) {
			return event.article.tags.some(it => it.identifier === 'empty');
		} else {
			return false;
		}
	}
	showDate() {
		return moment().format('DD.MM.yyyy');
	}
	checkIfArticleSanifair(article: Article) {
		console.log(article.name.de, article.tags);
		return article && article?.tags?.length > 0 && article?.tags?.find(it => it?.identifier === 'sanifair_voucher');
	}
	//default Article Price
	articlePrice(article: Article, isVerifiedArticle: boolean = false): number {
		if (article) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = article;
			articleGroup.groups = [];
			articleGroup.quantity = 1;
			if (article?.tags?.find(it => it?.identifier === 'sanifair_voucher')) {
				return -OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
			}
			return OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
		} else {
			return 0;
		}
	}
	articleTotalPrice(articleGroup: ArticleGroup): number {
		if (articleGroup) {
			if (articleGroup?.article?.tags?.find(it => it?.identifier === 'sanifair_voucher')) {
				return numberD(articleGroup?.totalPrice) ?? 0;
			}
			return OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.order?.preorder?.type);
		} else {
			return 0;
		}
	}
	getFlag() {
		return this.phoneCountries.find(country => country.tel === this.orderForm.get('phoneCode')?.value).icon;
	}

	async openHtmlModal(event: Event, page: PageEnum) {
		event.stopPropagation();
		await HtmlVisualizerModalComponent.show(this.modalCtrl, this.venue, page);
	}
	openFullSalesForceBlock() {
		this.openedSalesForceBlock = !this.openedSalesForceBlock;
	}
	trimString(value: string) {
		return value.trim();
	}
	checkName(article: Article) {
		return article && isDepositArticle(article);
	}
	checkNameVer(article) {
		return article && isDepositArticle(article);
	}
	async openHtmlModalAGB(event: Event, page: PageEnum) {
		event.stopPropagation();
		await HtmlVisualizerModalComponent.show(this.modalCtrl, null, page);
	}
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-feature-icon',
	templateUrl: './feature-icon.component.html',
	styleUrls: ['./feature-icon.component.css'],
})
export class FeatureIconComponent implements OnInit {
	@Input() featureType: string;
	@Input() isArticleAlign = false;

	constructor() {}

	ngOnInit() {}
}

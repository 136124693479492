import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home-page-slides',
	templateUrl: './home-page-slides.component.html',
	styleUrls: ['home-page-slides.component.scss'],
})
export class HomePageSlidesComponent implements OnInit {
	environment = environment;
	infoPages = [1, 2, 3, 4, 5, 6];

	slideOptions = {
		autoplay: {
			delay: 10000,
		},
		speed: 600,
		loop: true,
	};

	@ViewChild(IonSlides, { static: true }) slides: IonSlides;

	constructor() {}

	ngOnInit() {}

	nextSlide() {
		this.slides.slideNext();
	}

	prevSlide() {
		this.slides.slidePrev();
	}
}
